<template>
  <div>
    <immobile />
  </div>
</template>

<script>
import Immobile from '@/components/reports/Immobile.vue'
export default {
  components: { Immobile },
}
</script>

<style></style>
