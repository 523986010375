<template>
  <v-card outlined>
    <div class="d-flex align-center pa-4" style="gap: 1rem">
      <div>
        <v-img @click="openMaps" style="cursor: pointer;" class="rounded" :src="immobile.Address.mapUrl" width="100" height="100" />
      </div>

      <div>
        <h4>
          {{ immobile.Address | formatAddress(true) }}
        </h4>
        <div class="d-flex flex-wrap" style="gap: 0.2rem 1.5rem">
          <span v-for="(field, index) in fields" :key="index">
            <strong>{{ field.name }}:</strong> {{ immobile[field.key] }}
          </span>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'ImmobileReportHeader',
  data: () => ({
    fields: [
      {
        name: 'Tipo de imóvel',
        key: 'type',
      },
      {
        name: 'Registro',
        key: 'registry',
      },
      {
        name: 'Registro IPTU',
        key: 'iptuRegistry',
      },
      {
        name: 'Área',
        key: 'area',
      },
      {
        name: 'Bloco/Quadra',
        key: 'block',
      },
      {
        name: 'Lote',
        key: 'lot',
      },
    ],
  }),
  methods: {
    openMaps() {
      const url = new URL('https://www.google.com/maps/search/')
      url.searchParams.append('api', '1')
      url.searchParams.append(
        'query',
        `${this.immobile.Address.street}, ${this.immobile.Address.number}, ${this.immobile.Address.city} - ${this.immobile.Address.state}`,
      )
      window.open(url.href, '_blank')
    },
  },
  props: {
    immobile: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style></style>
